import { DicRecord } from '@entities/dictionaries';

export type Address = {
  city: DicRecord;
  street: string;
  home: string;
  apartment: string;
};

export type EducationDetails = {
  //city: DicRecord;
  isBachelor: boolean;
  year: DicRecord;
  field: DicRecord;
  otherField: string;
  additionalField: DicRecord;
  additionalAnotherFieldOfStudy: string;
};

export type PersonalInfo = {
  userId: string;
  birthday: any; //string | Date | null;
  firstName: string;
  lastName: string;
  //sector: DicRecord;
  gender: string;
  phone: string;
  phoneAdditional: string;
  // email: string;
  // emailConfirm: string;
  // emailTouched: boolean;
  addressById: Address;
  addressWhileStudying: Address;
  addressesMatch: boolean;
  educationDetails: EducationDetails;
  militaryServiceType: DicRecord<MilitaryType>;
  // valueExemption: boolean;
  serviceMoth: string;
  //learningDisabilityCertificate: boolean;
  birthdayHebrew: string;
  isUnder18address: boolean;
  under18City: DicRecord;
  under18District: DicRecord;
  under18Home: string;
  under18Street: string;
  under18Confirm: boolean;
  isEducationalStudent: boolean;
  isReligiousSector: boolean;
  isReservist?: boolean;
  daysInReserve?: string;
};

export type SendPersonalInfoResponse = {
  type: string;
  form_data: string;
  process_id: number;
  user_id: number;
  id: number;
  error?: any;
};

export type PersonalInfoResponse = PersonalInfoRequest & {
  id: number;
  user: {
    cognito_id: string;
    identity_number: string;
    phone: string;
    email: string;
    role: string;
    status: string;
    terms: number;
    register_period: number;
    id: number;
  };
};

export type AddressRequest = {
  city: string;
  street: string;
  house_number: string;
  apartment_number: string;
};

export type PersonalInfoRequest = {
  first_name: string;
  last_name: string;
  //identity_number: string;
  birthdate: string | null;
  gender: string;
  //sector_id?: number | string;
  phone: string;
  additional_phone: string;
  // email: string;
  city_symbol?: number | string;
  street: string;
  house_number: string;
  apartment_number: string;
  study_city_symbol?: number | string;
  study_street: string;
  study_house_number: string;
  study_apartment_number: string;
  //educational_city_id?: number | string;
  is_ba: boolean;
  study_year_number: string;
  //learning_disability_certificate?: boolean;
  field_of_study_id?: number | string;
  another_field_of_study: string;
  additional_field_of_study_id: string | number;
  additional_another_field_of_study: string;
  military_service_type: string;
  // value_exemption: boolean | null;
  age_18_city_symbol: number | '' | null;
  age_18_neighborhood_symbol: number | '' | null;
  age_18_street: string;
  age_18_house_number: string;
  age_18_not_israel: boolean;
  age_18_correctness_information: boolean;
  is_educational_student: boolean;
  is_religious_sector: boolean;
  service_month?: number | string;
  birthdate_hebrew?: string;
  is_reservist?: boolean;
  days_in_reserve?: number | string;
};

export enum MilitaryType {
  MILITARY_SERVICE = 'MILITARY_SERVICE',
  NATIONAL_SERVICE = 'NATIONAL_SERVICE',
  CIVIL_SERVICE = 'CIVIL_SERVICE',
  NOT_SERVED = 'NOT_SERVED',
  NONE = '',
}
