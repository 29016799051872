import { generateId } from '../../CUI/utils';
import {
  AdditionalInfo,
  Address,
  BankDetails,
  DisabledFamilyMember,
  DocumentsForm,
  FamilyMembersType,
  MilitaryType,
  ModelState,
  PersonalInfo,
  WizardSteps,
} from './types';

export const addressDefaultValues: Address = {
  city: { value: '', text: '' },
  home: '',
  apartment: '',
  street: '',
};

export const personalInfoDefaultValues: PersonalInfo = {
  addressById: addressDefaultValues,
  addressWhileStudying: addressDefaultValues,
  addressesMatch: false,
  birthday: null,
  educationDetails: {
    //city: { text: '', value: '' },
    additionalField: { text: 'ללא', value: '' },
    field: { text: '', value: '' },
    otherField: '',
    year: { text: '', value: '' },
    isBachelor: false,
    additionalAnotherFieldOfStudy: '',
  },
  // email: '',
  // emailConfirm: '',
  // emailTouched: false,
  firstName: '',
  lastName: '',
  gender: 'MALE',
  militaryServiceType: { text: '', value: MilitaryType.NONE },
  phone: '',
  phoneAdditional: '',
  //sector: { text: '', value: '' },
  // valueExemption: false,
  userId: '',
  serviceMoth: '',
  //learningDisabilityCertificate: false,
  birthdayHebrew: '',
  isUnder18address: false,
  under18City: { text: '', value: '' },
  under18District: { text: '', value: '' },
  under18Home: '',
  under18Street: '',
  under18Confirm: true,
  isEducationalStudent: false,
  isReligiousSector: false,
  isReservist: false,
  daysInReserve: '',
};

export const bankDetailsDefaultValue: BankDetails = {
  bank: { value: '', text: '' },
  accountNumber: '',
  branch: '',
};

export const createFamilyMember = (): DisabledFamilyMember => ({
  id: generateId(),
  percent: '',
  type: { text: undefined, value: undefined },
});
export const additionalInfoDefaultValues: AdditionalInfo = {
  maritalStatus: { text: undefined, value: undefined },
  isMaritalStatusGap: false,
  maritalStatusGap: '',
  childrenCount: '',
  isChildrenCountGap: false,
  childrenCountGap: '',
  siblingsCount: '',
  siblingsStudentsCount: '',
  isNewcomer: false,
  repatriationDate: '',
  isOrphan: false,
  orphanOfParentsCount: { text: undefined, value: undefined },
  isCutOfFromParents: false,
  cutOfFromParentsCount: { text: undefined, value: undefined },
  isSingleParent: false,
  isDisabledFamilyMembers: false,
  disabledFamilyMembers: [createFamilyMember()],
  // tuitionCost: '',
  // isFundedByEmployer: false,
  // amountOfFinancing: '',
};

export const familyIncomeDefaults = [
  {
    member_type: FamilyMembersType.PARENTONE,
    incomeType: { text: '', value: '' },
    document: '',
    allowance: { text: '', value: '' },
  },
  {
    member_type: FamilyMembersType.PARENTONE,
    incomeType: { text: '', value: '' },
    document: '',
    allowance: { text: '', value: '' },
  },
  {
    member_type: FamilyMembersType.SPOUSE,
    incomeType: { text: '', value: '' },
    document: '',
    allowance: { text: '', value: '' },
  },
  {
    member_type: FamilyMembersType.CANDIDATE,
    incomeType: { text: '', value: '' },
    document: '',
    allowance: { text: '', value: '' },
  },
];

export const documentsDefaultValue: DocumentsForm = {
  documentPath: '',

  isIdentityBiometric: true,
  frontIdentity: '',
  backIdentity: '',
  attachmentIdentity: '',

  parentOneIsIdentityBiometric: true,
  parentOneFrontIdentity: '',
  parentOneBackIdentity: '',
  parentOneAttachmentIdentity: '',
  parentOneDeathCertificate: '',

  parentTwoIsIdentityBiometric: true,
  parentTwoFrontIdentity: '',
  parentTwoBackIdentity: '',
  parentTwoAttachmentIdentity: '',
  parentTwoDeathCertificate: '',

  confirmationStudies: '',
  // learningDisabilityCertificate: '',
  approvalAnnualTuitionFees: '',
  approvalStudyBrothersUnder30: [],

  bankAccountReference: '',

  familyDocuments: familyIncomeDefaults,

  otherIncomeDetails: '',
  isAnotherIncome: false,
  otherIncomeFile: '',
  approveIncomes: false,

  armyTypeDocument: '',
  reservistDocument: '',

  singleParentApproval: '',

  newcomerDocument: '',

  disabilityDocuments: [],
  additionalDocument: '',
  declarationCorrectnessInformation: false,
};

export const modelStateDefaultValue: ModelState = {
  currentStep: WizardSteps.ONE,
  changed: false,
  personalInfo: personalInfoDefaultValues,
  bankDetails: bankDetailsDefaultValue,
  additionalInfo: additionalInfoDefaultValues,
  documents: documentsDefaultValue,
  documentsPrefilled: {},
  isModalOpen: false,
  isResultModalOpen: false,
  backToFix: [],
  validationError: [],
};
