import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

import { globalConfig } from '@/globalConfig';
import * as CUI from '@CUI';

import { dictionariesModel } from '@entities/dictionaries';
import { Optional } from '@common/ui';
import {
  OptionsInput,
  CheckBox,
  LabelWithHelp,
  ShekelRender,
  OpionalTooltip,
  Modal,
} from '@common/perach-ui';
import { useActions } from '@common/store-utils/hooks';
import { formatCurrency } from '@common/utils';

import isPasswordValid from '../../utility/validators/pass-validator';
import { actions, selectors } from './model';
import {
  CurrentlyStudyingType,
  formDataDefaultValues,
  ResidencyStatusType,
  ScholarshipsSelectType,
} from './types';
import { config } from './config';
import { stepOneFields } from '@features/Registration/fields';
import { subtractYears } from '@features/Registration/utils';
import { ModalWindow } from '@entities/settings/ModalWindow';

/**
 * Check eligibility Form component
 * @returns React.FC
 * */
export const CheckEligibilityForm: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [additionalTermsConfirm, setAdditionalTermsConfirm] = useState(false);

  const { checkUserEligibility, update } = useActions(actions);

  const { getDictionaries } = useActions(dictionariesModel.actions);

  const errorBox = useRef<HTMLDivElement>(null);

  const isValidForm = selectors.useIsValid();
  const serverValidationErrors = selectors.useServerValidationErrors();

  const academicInstitutionList =
    dictionariesModel.selectors.useAcademicInstitution();

  const checkEligibilityData =
    selectors.useCheckEligibilityData() || formDataDefaultValues;

  const CITIZENSHIP_OPTIONS = [
    { value: ResidencyStatusType.CITIZEN, label: t('CITIZEN') },
    { value: ResidencyStatusType.RESIDENT, label: t('RESIDENT') },
    { value: ResidencyStatusType.NONE, label: t('NOT_CITIZEN_AND_RESIDENT') },
  ];

  const CURRENTLY_STUDYING = [
    { value: CurrentlyStudyingType.BACHELOR, label: t('FIRST_DEGREE') },
    { value: CurrentlyStudyingType.PREPARATORY, label: t('PREPARING') },
    { value: CurrentlyStudyingType.MAHAT, label: t('MAHAT') },
    { value: CurrentlyStudyingType.OTHER, label: t('OTHER') },
  ];

  const BOOL_OPTIONS = [
    { value: true, label: t('YES') },
    { value: false, label: t('NO') },
  ];

  const handleFieldChange = (e: CUI.InputTypes.ChangeEvent) =>
    update({
      changes: {
        [e.component.name as string]: e.component.value,
      },
    });

  const handleCheckboxChange = (e: CUI.CheckboxTypes.ChangeEvent) =>
    update({
      changes: {
        scholarships: {
          ...checkEligibilityData?.scholarships,
          [e.component.name as string]: e.component.value,
          [ScholarshipsSelectType.NONE]: false,
        },
      },
    });

  useEffect(() => {
    getDictionaries();
  }, []);

  useEffect(() => {
    if (checkEligibilityData?.passwordConfirmation) {
      CUI.form(config.formName, 'passwordConfirmation').validate();
    }
  }, [checkEligibilityData?.password]);

  useEffect(() => {
    if (serverValidationErrors?.length) {
      setTimeout(
        () =>
          errorBox?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          }),
        100
      );
    }
  }, [serverValidationErrors]);

  console.log(isModalOpen);
  return (
    <>
      <div className="page-title">{t('CHECK_ELIGIBILITY_PAGE_TITLE')}</div>
      <div className="page-subtitle" ref={errorBox}>
        {t('CHECK_ELIGIBILITY_PAGE_SUBTITLE')}
      </div>
      <div className="form-container ph-25">
        <Optional when={!!serverValidationErrors?.length}>
          <Row>
            <Col>
              <div className="error-box inline">
                <FontAwesomeIcon icon={faCircleExclamation} className="ml-16" />
                <span>
                  {serverValidationErrors &&
                    serverValidationErrors.length > 0 &&
                    serverValidationErrors.map((e) => (
                      <React.Fragment key={CUI.utils.generateId()}>
                        {e}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </Col>
          </Row>
        </Optional>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>{t('IDENTITY_NUMBER')}</CUI.Label>
            <CUI.Input
              form={config.formName}
              name="identityNumber"
              value={checkEligibilityData?.identityNumber}
              onChange={handleFieldChange}
              allowedSymbols="numbers"
              validator={'israelId'}
              maxLength={9}
              minLength={7}
              isRequired
              requiredMessage={t('FIELD_REQUIRED')}
              invalidMessage={t('ENTER_CORRECT_ID')}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>{t('IDENTITY_NUMBER')}</CUI.Label>
            <CUI.DatePicker
              _wideList
              form={'test'}
              name={stepOneFields.birthday}
              placeholder={' '}
              value={null}
              format={''}
              max={subtractYears(14)}
              min={new Date('01-01-1901')}
              isRequired
              requiredMessage={t('FIELD_REQUIRED')}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <LabelWithHelp
              label={t('PHONE_NUMBER')}
              helpText={
                'סיסמאות חד־פעמיות מהמערכת נשלחות לטלפון האישי, למכשירים ולקווים שתומכים בשירות קבלת SMS. טלפונים כשרים, שתומכים בהסבת SMS להודעה קולית, יקבלו הודעה כזו. טלפונים כשרים שלא תומכים בהסבת SMS להודעה קולית, לא יוכלו לקבל שיחה נוכח החסימה. לכן חובה לוודא שהטלפון מקבל הודעות SMS כתובות או כשיחה'
              }
            />
            <CUI.Input
              form={config.formName}
              name="phone"
              value={checkEligibilityData?.phone}
              onChange={handleFieldChange}
              allowedSymbols="numbers"
              maxLength={10}
              minLength={9}
              validator={(v) => v.length >= 9 && v.length <= 10}
              isRequired
              requiredMessage={t('FIELD_REQUIRED')}
              invalidMessage={t('ENTER_CORRECT_PHONE')}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>{t('EMAIL_ADDRESS')}</CUI.Label>
            <CUI.Input
              name={'email'}
              form={config.formName}
              invalidMessage={t('ERRORS.ENTER_CORRECT_EMAIL')}
              requiredMessage={t('FIELD_REQUIRED')}
              value={checkEligibilityData?.email}
              onChange={handleFieldChange}
              validator={'email'}
              isRequired
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>{t('EMAIL_ADDRESS_CONFIRM')}</CUI.Label>
            <CUI.Input
              name={'emailConfirm'}
              form={config.formName}
              invalidMessage={t('ERRORS.ENTER_CORRECT_EMAIL')}
              requiredMessage={t('FIELD_REQUIRED')}
              value={checkEligibilityData?.emailConfirm}
              validator={(value) => value === checkEligibilityData?.email}
              pasteDisabled={true}
              onChange={handleFieldChange}
              isRequired
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <LabelWithHelp
              label={t('PASSWORD')}
              helpText={t('TOOLTIP_PASSWORD')}
            />
            <CUI.Password
              showEvaluationMessage={false}
              form={config.formName}
              name="password"
              value={checkEligibilityData?.password}
              onChange={handleFieldChange}
              validator={(v) => isPasswordValid(v)}
              maxLength={20}
              isRequired
              requiredMessage={t('FIELD_REQUIRED')}
              invalidMessage={t('ENTER_CORRECT_PASSWORD')}
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>{t('PASSWORD_CONFIRMATION')}</CUI.Label>
            <CUI.Password
              form={config.formName}
              showEvaluationMessage={false}
              name="passwordConfirmation"
              value={checkEligibilityData?.passwordConfirmation}
              onChange={handleFieldChange}
              validator={(v) => v === checkEligibilityData?.password}
              maxLength={20}
              isRequired
              requiredMessage={t('FIELD_REQUIRED')}
              invalidMessage={t('ENTER_SAME_PASSWORD')}
              onPaste={(e: any) => {
                e.preventDefault();
                return false;
              }}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <LabelWithHelp label={t('SCHOOL')} helpText={t('TOOLTIP_SCHOOL')} />
            <CUI.DropDownSelect
              form={config.formName}
              name="academicInstitution"
              data={academicInstitutionList}
              textField={'text'}
              //              shouldAllowEmpty
              shouldFilterValues
              requiredMessage={t('FIELD_REQUIRED')}
              value={checkEligibilityData?.academicInstitution || null}
              onChange={(e) =>
                update({ changes: { academicInstitution: e.component.value } })
              }
              isRequired
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>{t('CURRENTLY_STUDYING')}</CUI.Label>
            <OptionsInput
              options={CURRENTLY_STUDYING}
              value={
                checkEligibilityData?.currentlyStudying ||
                CurrentlyStudyingType.BACHELOR
              }
              onChange={(val) =>
                update({ changes: { currentlyStudying: val } })
              }
            />
          </Col>
        </Row>
        <Optional
          when={
            checkEligibilityData?.currentlyStudying ===
            CurrentlyStudyingType.BACHELOR
          }
        >
          <Row className="g-2 gx-5 mb-40">
            <Col className="col-12">
              <CUI.Label _labelPerach>{t('OTHER_BACHELOR')}</CUI.Label>
              <OptionsInput
                options={BOOL_OPTIONS}
                value={checkEligibilityData?.otherBachelor || false}
                onChange={(val) => update({ changes: { otherBachelor: val } })}
              />
            </Col>
          </Row>
        </Optional>
        <Optional
          when={
            checkEligibilityData?.currentlyStudying ===
            CurrentlyStudyingType.PREPARATORY
          }
        >
          <Row className="g-2 gx-5 mb-40">
            <Col className="col-12">
              <CUI.Label _labelPerach>{t('ELIGIBLE_FOR_ASSISTANCE')}</CUI.Label>
              <OptionsInput
                options={BOOL_OPTIONS}
                value={checkEligibilityData?.eligibleForAssistance}
                onChange={(val) => {
                  update({ changes: { eligibleForAssistance: val } });
                }}
              />
              {!checkEligibilityData?.eligibleForAssistance && (
                <CUI.Label _labelPerach _txtDanger>
                  {t('STATEMENT_WILL_BE_CHECKED')}
                </CUI.Label>
              )}
            </Col>
          </Row>
        </Optional>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12 checkbox-group">
            <CUI.Label _labelPerach>{t('WITCH_SCHOLARSHIPS')}</CUI.Label>
            <CheckBox
              value={checkEligibilityData?.scholarships.MAROM}
              name={ScholarshipsSelectType.MAROM}
              form={config.formName}
              onChange={handleCheckboxChange}
            >
              {t('MAROM')}
            </CheckBox>

            <CheckBox
              value={checkEligibilityData?.scholarships.TENA}
              name={ScholarshipsSelectType.TENA}
              form={config.formName}
              onChange={handleCheckboxChange}
            >
              {t('TENA')}
            </CheckBox>

            <CheckBox
              value={checkEligibilityData?.scholarships.IRTIKA}
              name={ScholarshipsSelectType.IRTIKA}
              form={config.formName}
              onChange={handleCheckboxChange}
            >
              {t('IRTIKA')}
            </CheckBox>

            <CheckBox
              value={checkEligibilityData?.scholarships.PERACH}
              name={ScholarshipsSelectType.PERACH}
              form={config.formName}
              onChange={handleCheckboxChange}
            >
              {t('PERACH')}
            </CheckBox>

            <CheckBox
              value={checkEligibilityData?.scholarships.NONE}
              name={ScholarshipsSelectType.NONE}
              form={config.formName}
              onChange={() =>
                update({
                  changes: {
                    scholarships: {
                      [ScholarshipsSelectType.TENA]: false,
                      [ScholarshipsSelectType.MAROM]: false,
                      [ScholarshipsSelectType.IRTIKA]: false,
                      [ScholarshipsSelectType.PERACH]: false,
                      [ScholarshipsSelectType.NONE]: true,
                    },
                  },
                })
              }
            >
              {t('NO_ONE')}
            </CheckBox>
          </Col>
        </Row>

        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>
              {t('RECEIVED_SCHOLARSHIP_BEFORE')}
            </CUI.Label>
            <OptionsInput
              options={BOOL_OPTIONS}
              value={checkEligibilityData?.receivedScholarship || false}
              onChange={(val) =>
                update({ changes: { receivedScholarship: val } })
              }
            />
          </Col>
        </Row>

        <Optional when={checkEligibilityData?.receivedScholarship === true}>
          <Row className="g-2 gx-5 mb-40">
            <Col className="col-12 col-md-6">
              <CUI.Label _labelPerach>
                {t('ADDITIONAL_SCHOLARSHIP_NAME')}
              </CUI.Label>
              <CUI.Input
                form={config.formName}
                name="receivedScholarshipName"
                value={checkEligibilityData?.receivedScholarshipName}
                onChange={handleFieldChange}
                isRequired
                requiredMessage={t('FIELD_REQUIRED')}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <CUI.Label _labelPerach>
                {t('ADDITIONAL_SCHOLARSHIP_AMOUNT')}
              </CUI.Label>
              <CUI.Input
                maxLength={6}
                form={config.formName}
                name="receivedScholarshipAmount"
                value={checkEligibilityData?.receivedScholarshipAmount}
                onChange={handleFieldChange}
                onBlur={() =>
                  update({
                    changes: {
                      receivedScholarshipAmount: formatCurrency(
                        checkEligibilityData?.receivedScholarshipAmount
                      ),
                    },
                  })
                }
                onFocus={() =>
                  update({
                    changes: {
                      receivedScholarshipAmount:
                        checkEligibilityData?.receivedScholarshipAmount.replace(
                          ' ',
                          ''
                        ),
                    },
                  })
                }
                isRequired
                allowedSymbols="numbers"
                inputRender={ShekelRender}
                requiredMessage={t('FIELD_REQUIRED')}
              />
            </Col>
          </Row>
        </Optional>

        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <LabelWithHelp
              label={t('NOT_DESERTER')}
              helpText={
                'עריק או נפקד הוא חייל שגוייס ונעדר מהצבא ללא אישור. אדם שפטור באישור משירות צבאי- אינו עריק או נפקד\n'
              }
            />

            <OptionsInput
              options={BOOL_OPTIONS}
              value={checkEligibilityData?.notDeserter || false}
              onChange={(val) => update({ changes: { notDeserter: val } })}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CUI.Label _labelPerach>{t('CITIZENSHIP_STATUS')}</CUI.Label>
            <OptionsInput
              options={CITIZENSHIP_OPTIONS}
              value={
                checkEligibilityData?.residencyStatus ||
                ResidencyStatusType.RESIDENT
              }
              onChange={(val) => update({ changes: { residencyStatus: val } })}
            />
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CheckBox
              value={!!checkEligibilityData?.termsConfirm}
              name="termsConfirm"
              form={config.formName}
              onChange={(e: CUI.CheckboxTypes.ChangeEvent) =>
                update({
                  changes: {
                    termsConfirm: e.component.value
                      ? Date.now().toString()
                      : '',
                  },
                })
              }
            >
              {t('I_ACCEPT_TERMS')}
            </CheckBox>
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CheckBox
              value={additionalTermsConfirm}
              name="additionalTermsConfirm"
              form={config.formName}
              onChange={(e: CUI.CheckboxTypes.ChangeEvent) =>
                setAdditionalTermsConfirm(e.component.value)
              }
            >
              {`קראתי ואני מאשר.ת את `}
              <a href={'/assets/legal.pdf'} target={'_blank'}>
                תנאי השימוש
              </a>
              {' ו'}
              <a href={'/privacy'} target={'_blank'}>
                מדיניות הפרטיות
              </a>
            </CheckBox>
          </Col>
        </Row>

        <Row>
          <Col className="col-12 col-md-9 mb-40">
            <OpionalTooltip
              option={!isValidForm && !serverValidationErrors?.length}
              text={t('TOOLTIP_CHECK_ELEGIBILITY')}
            >
              <CUI.Button
                _dBlock
                _w100
                _signInButton
                _btnPrimaryPerach
                isDisabled={!isValidForm || !additionalTermsConfirm}
                form={config.formName}
                onClick={() => setIsConfirmModalOpen(true)}
              >
                {t('CHECK_ELIGIBILITY_PAGE_TITLE')}
              </CUI.Button>
            </OpionalTooltip>
          </Col>
          <Col className="col-12 col-md-3 mb-40">
            <CUI.Button
              _dBlock
              _w100
              _signInButton
              _btnInfoPerach
              onClick={() => setIsModalOpen(true)}
            >
              {t('EXIT')}
            </CUI.Button>
          </Col>
        </Row>
      </div>
      {/*EXIT MODAL*/}
      <ModalWindow modal_key={'eligibilityExit'} forceOpen={isModalOpen} />
      {/*<Modal*/}
      {/*  isModalOpen={isModalOpen || false}*/}
      {/*  header={t('ELIGIBILITY.MODAL.HEADER')}*/}
      {/*  modalBody={*/}
      {/*    <CUI.Div _modalBodyContainer>*/}
      {/*      <CUI.Span _modalBodyHeader _mt24>*/}
      {/*        {t('ELIGIBILITY.MODAL.TEXT')}*/}
      {/*      </CUI.Span>*/}
      {/*      <CUI.Div _inline>*/}
      {/*        <CUI.Button*/}
      {/*          _btnPrimaryPerach*/}
      {/*          _mb16*/}
      {/*          _mt24*/}
      {/*          _ml16*/}
      {/*          onClick={() => {*/}
      {/*            setIsModalOpen(false);*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {t('ELIGIBILITY.MODAL.BUTTON_CONFIRM')}*/}
      {/*        </CUI.Button>*/}
      {/*        <CUI.Button*/}
      {/*          _btnInfoPerach*/}
      {/*          _noMinWidthMob*/}
      {/*          _mb16*/}
      {/*          _mt24*/}
      {/*          onClick={() => {*/}
      {/*            setIsModalOpen(false);*/}
      {/*            history.push(globalConfig.routes.main());*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {t('ELIGIBILITY.MODAL.BUTTON_CLOSE')}*/}
      {/*        </CUI.Button>*/}
      {/*      </CUI.Div>*/}
      {/*    </CUI.Div>*/}
      {/*  }*/}
      {/*  closeModal={() => setIsModalOpen(false)}*/}
      {/*/>*/}

      {/* Confirm modal */}
      <ModalWindow
        modal_key={'eligibilityConfirm'}
        forceOpen={isConfirmModalOpen}
        positiveAction={checkUserEligibility}
      />
      {/*<Modal*/}
      {/*  isModalOpen={isConfirmModalOpen || false}*/}
      {/*  header={t('ELIGIBILITY.CONFIRM_MODAL.HEADER')}*/}
      {/*  modalBody={*/}
      {/*    <CUI.Div _modalBodyContainer>*/}
      {/*      <CUI.Span _modalBodyHeader _mt24>*/}
      {/*        {t('ELIGIBILITY.CONFIRM_MODAL.TEXT')}*/}
      {/*      </CUI.Span>*/}
      {/*      <CUI.Div _inline>*/}
      {/*        <CUI.Button*/}
      {/*          _btnPrimaryPerach*/}
      {/*          _mb16*/}
      {/*          _mt24*/}
      {/*          _ml16*/}
      {/*          onClick={() => {*/}
      {/*            setIsConfirmModalOpen(false);*/}
      {/*            checkUserEligibility();*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {t('ELIGIBILITY.CONFIRM_MODAL.BUTTON_CONFIRM')}*/}
      {/*        </CUI.Button>*/}
      {/*        <CUI.Button*/}
      {/*          _btnInfoPerach*/}
      {/*          _noMinWidthMob*/}
      {/*          _mb16*/}
      {/*          _mt24*/}
      {/*          onClick={() => {*/}
      {/*            setIsConfirmModalOpen(false);*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {t('ELIGIBILITY.CONFIRM_MODAL.BUTTON_CLOSE')}*/}
      {/*        </CUI.Button>*/}
      {/*      </CUI.Div>*/}
      {/*    </CUI.Div>*/}
      {/*  }*/}
      {/*  closeModal={() => setIsConfirmModalOpen(false)}*/}
      {/*/>*/}
    </>
  );
};
