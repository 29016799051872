import { BankDetails } from './bankDetails';
import { PersonalInfo } from './personalInfo';
import { AdditionalInfo } from './additionalInfo';
import { DocumentsForm, PrefilledDocuments } from './documents';

export enum WizardSteps {
  NONE = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export type ModelState = {
  currentStep: WizardSteps;
  changed: boolean;
  personalInfo: PersonalInfo;
  bankDetails: BankDetails;
  additionalInfo: AdditionalInfo;
  documents: DocumentsForm;
  documentsPrefilled: PrefilledDocuments;
  isModalOpen: boolean;
  backToFix: BackToFixResponse;
  validationError: string[];
  isResultModalOpen: boolean;
};

export type RegistrationPrefilledDataResponse = {
  identity_number: string;
  phone: string;
  email: string;
  academic_institution: {
    name: string;
    is_sponsored: boolean;
  };
  currently_studying: boolean;
};

export type UserPersonalDataResponse = {
  id: 2;
  first_name: string;
  last_name: string;
  birthdate: string;
  birthdate_hebrew: string;
  gender: string;
  //sector_id: number;
  additional_phone: string;
  city_id: number;
  street: string;
  house_number: string;
  apartment_number: string;
  study_city_id: number;
  study_street: string;
  study_house_number: string;
  study_apartment_number: string;
  educational_city_id: number;
  is_ba: boolean;
  study_year_number: string;
  learning_disability_certificate: boolean;
  field_of_study_id: number;
  another_field_of_study: string;
  additional_field_of_study_id: number;
  additional_another_field_of_study: string;
  military_service_type: string;
  // value_exemption: boolean;
  service_month: number;
  is_educational_student: boolean;
  is_religious_sector: boolean;
  user_id: number;
  age_18_city_symbol: number;
  age_18_neighborhood_symbol: number;
  age_18_street: string;
  age_18_house_number: string;
  age_18_not_israel: boolean;
  age_18_correctness_information: boolean;
  is_reservist: boolean;
  days_in_reserve: string;
  city: {
    city_symbol: number;
    city_name: string;
  };
  study_city: {
    city_symbol: number;
    city_name: string;
  };
  educational_city: {
    id: number;
    city_name: string;
  };
  sector: {
    id: number;
    label: string;
    value: string;
  };
  field_of_study: {
    id: number;
    label: string;
    value: string;
  };
  additional_field_of_study: {
    id: number;
    label: string;
    value: string;
  };
};

export type BackToFixEntry = {
  comment: string;
  failComment: string;
  failType: string;
  hebrew_name: string;
  status: string;
};

export type BackToFixResponse = Record<
  string,
  BackToFixEntry | BackToFixEntry[]
>[];

export type DisabilityLabels =
  | 'PARENT_ONE'
  | 'PARENT_TWO'
  | 'BROTHER_SISTER'
  | 'SPOUSE'
  | 'CANDIDATE';
