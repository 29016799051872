import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBallotCheck,
  faCircleExclamation,
  faCreditCard,
  faFileArrowUp,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

import { globalConfig } from '@/globalConfig';

import * as CUI from '@CUI';
import { Modal } from '@common/perach-ui';
import { Stepper } from '@common/perach-ui/Stepper';
import { useActions } from '@common/store-utils/hooks';

import { dictionariesModel } from '@entities/dictionaries';
import { userModel } from '@entities/user';

import { WizardSteps } from '../types';
import { actions, selectors } from '../model';
import { config } from '../config';

import { PersonalInfoForm } from './PersonalInfoForm';
import { BankDetailsForm } from './BankDetailsForm';
import { AdditionalInfoForm } from './AdditionalInfoForm';
import { FilesUploadForm } from './FilesUploadForm';
import { Col, Row } from 'reactstrap';
import { Optional } from '@common/ui';
import { isAdmin, isTeamManager } from '@common/utils';
import { ModalWindow } from '@entities/settings/ModalWindow';

type RegistrationContainerProps = {
  disabledFieldsStepOne?: Record<string, boolean>;
  disabledFieldsStepTwo?: Record<string, boolean>;
  disabledFieldsStepThree?: Record<string, boolean>;
  disabledFieldsStepFour?: Record<string, boolean>;
  fieldsToFix?: Record<string, string>;
  requestStatus?: string;
  isAdminFlow?: boolean;
};

/**
 * Registration container
 * @returns React.FC
 * */
export const RegistrationContainer: React.FC<RegistrationContainerProps> = ({
  disabledFieldsStepOne,
  disabledFieldsStepTwo,
  disabledFieldsStepThree,
  disabledFieldsStepFour,
  fieldsToFix,
  requestStatus,
  isAdminFlow = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const currentStep = selectors.useCurrentStep();
  const isModalOpen = selectors.useIsModalOpen();
  const validationError = selectors.useValidationError();
  const isSponsored = userModel.selectors.useIsSponsored();
  const { getDictionaries } = useActions(dictionariesModel.actions);
  const {
    autoSaveRegistration,
    setStep,
    prefillUserPersonalData,
    setIsModalOpen,
  } = useActions(actions);

  const errorBox = useRef<HTMLDivElement>(null);

  const goToStepOne = () => setStep(WizardSteps.ONE);

  const goToStepTwo = () =>
    !requestStatus
      ? currentStep !== WizardSteps.ONE
        ? setStep(WizardSteps.TWO)
        : null
      : setStep(WizardSteps.TWO);

  const goToStepThree = () =>
    !requestStatus
      ? currentStep !== WizardSteps.ONE && currentStep !== WizardSteps.TWO
        ? setStep(WizardSteps.THREE)
        : null
      : setStep(WizardSteps.THREE);

  const goToStepFour = () =>
    !requestStatus ? null : setStep(WizardSteps.FOUR);

  const steps = [
    {
      isActive: currentStep === WizardSteps.ONE,
      isDone: currentStep !== WizardSteps.ONE,
      iconRender: <FontAwesomeIcon icon={faUser} />,
      label: t('PERSONAL_INFO'),
      onClick: goToStepOne,
    },
    {
      isActive: currentStep === WizardSteps.TWO,
      shouldSkip: isSponsored,
      isDone:
        currentStep === WizardSteps.THREE || currentStep === WizardSteps.FOUR,
      iconRender: <FontAwesomeIcon icon={faCreditCard} />,
      label: t('BANK_DETAILS'),
      onClick: goToStepTwo,
    },
    {
      isActive: currentStep === WizardSteps.THREE,
      isDone: currentStep === WizardSteps.FOUR,
      iconRender: <FontAwesomeIcon icon={faBallotCheck} />,
      label: t('ADDITIONAL_INFO'),
      onClick: goToStepThree,
    },
    {
      isActive: currentStep === WizardSteps.FOUR,
      isDone: false,
      iconRender: <FontAwesomeIcon icon={faFileArrowUp} />,
      label: t('UPLOAD_DOCUMENTS'),
      onClick: goToStepFour,
    },
  ];

  useEffect(() => {
    const autoSaveOnTimer = setInterval(() => {
      if (!isAdminFlow && currentStep !== WizardSteps.FOUR)
        autoSaveRegistration();
    }, config.autoSaveInterval);
    return () => clearInterval(autoSaveOnTimer);
  }, []);

  useEffect(() => {
    getDictionaries();
    prefillUserPersonalData();
  }, []);

  useEffect(() => {
    if (!!validationError?.length) {
      window.scrollTo(0, 0);
    }
  }, [validationError]);

  const closeModalCallback = useCallback(
    () => setIsModalOpen(false),
    [setIsModalOpen]
  );
  return (
    <>
      <div className="page-title">{t('APPLICATION_PAGE_TITLE')}</div>
      <div className="form-container" ref={errorBox}>
        <Stepper steps={steps} />

        <Optional when={!!validationError?.length}>
          <Row>
            <Col>
              <div className="error-box inline">
                <FontAwesomeIcon icon={faCircleExclamation} className="ml-16" />
                <span>
                  {validationError &&
                    Array.isArray(validationError) &&
                    validationError.map((e) => (
                      <React.Fragment key={CUI.utils.generateId()}>
                        {e}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </Col>
          </Row>
        </Optional>

        {/*<div className="auth-register-form mt-2">*/}
        {currentStep === WizardSteps.ONE && (
          <PersonalInfoForm disabledFields={disabledFieldsStepOne} />
        )}
        {currentStep === WizardSteps.TWO && (
          <BankDetailsForm disabledFields={disabledFieldsStepTwo} />
        )}
        {currentStep === WizardSteps.THREE && (
          <AdditionalInfoForm disabledFields={disabledFieldsStepThree} />
        )}
        {currentStep === WizardSteps.FOUR && (
          <FilesUploadForm
            disabledFields={disabledFieldsStepFour}
            fieldsToFix={fieldsToFix}
            isAdminFlow={isAdminFlow}
          />
        )}
        {/*</div>*/}
      </div>
      <ModalWindow
        modal_key={'exitRegistration'}
        positiveAction={autoSaveRegistration}
        forceOpen={isModalOpen}
        closeModalCallback={closeModalCallback}
      />
      {/*<Modal*/}
      {/*  isModalOpen={isModalOpen || false}*/}
      {/*  header={t('REGISTRATION.MODAL.HEADER')}*/}
      {/*  modalBody={*/}
      {/*    <CUI.Div _modalBodyContainer>*/}
      {/*      <CUI.Span _modalBodyHeader _mt24>*/}
      {/*        {t('REGISTRATION.MODAL.TEXT')}*/}
      {/*      </CUI.Span>*/}
      {/*      <CUI.Div _inline>*/}
      {/*        <CUI.Button*/}
      {/*          _btnPrimaryPerach*/}
      {/*          _mb16*/}
      {/*          _mt24*/}
      {/*          _ml16*/}
      {/*          onClick={() => {*/}
      {/*            autoSaveRegistration();*/}
      {/*            setIsModalOpen(false);*/}
      {/*            history.push(globalConfig.routes.personalArea());*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {t('REGISTRATION.MODAL.BUTTON_CONFIRM')}*/}
      {/*        </CUI.Button>*/}
      {/*        <CUI.Button*/}
      {/*          _btnInfoPerach*/}
      {/*          _mb16*/}
      {/*          _mt24*/}
      {/*          onClick={() => setIsModalOpen(false)}*/}
      {/*        >*/}
      {/*          {t('REGISTRATION.MODAL.BUTTON_CLOSE')}*/}
      {/*        </CUI.Button>*/}
      {/*      </CUI.Div>*/}
      {/*    </CUI.Div>*/}
      {/*  }*/}
      {/*  closeModal={() => setIsModalOpen(false)}*/}
      {/*/>*/}
    </>
  );
};
