import React, { useEffect, useState } from 'react';

import * as CUI from '@CUI';

import { Modal } from '@common/perach-ui';

import { selectors } from './model/selectors';

type Props = {
  modal_key: string;
  forceOpen?: boolean;
  positiveAction?: () => void;
  negativeAction?: () => void;
  closeModalCallback?: () => void;
};

export const ModalWindow: React.FC<Props> = ({
  modal_key,
  forceOpen = false,
  positiveAction,
  negativeAction,
  closeModalCallback,
}) => {
  const mock = [
    {
      should_show: true,
      initial_open: true,
      key: 'homePage',
      header: 'ברוכים הבאים למלגת מיל-go! \n',
      text:
        ' חשוב! נא לקרוא את\n' +
        "            <a href={'https://static.cua.org.il/public/admin/pdf/general.pdf'}>\n" +
        '              ההודעה הבאה\n' +
        '            </a>',

      is_positive_button: true,
      is_positive_action: false,
      positive_redirect: null,
      positive_button_text: 'סגור',

      is_negative_button: false,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: null,
    },
    {
      should_show: true,
      initial_open: false,
      key: 'deleteFile',
      header: 'מחיקה מוחלטת של מסמך',
      text: `
        שימו לב, הסרת המסמך היא מחיקה מוחלטת שלו מהמאגר.
        <br />
        לא ניתן יהיה לשחזר את המסמך או לצפות בו יותר.
        <p />
        אם המסמך הזה רלוונטי ונדרש כחלק מהמסכים שיש להעלות לשדה זה, יש לסרוק
        אותו יחד עם המסמכים האחרים, ולהעלותם כקובץ אחד (המסמך הזה, ואת כל
        המסמכים האחרים הנדרשים בשדה זה).
      `,
      is_positive_button: true,
      is_positive_action: true,
      positive_redirect: null,
      positive_button_text: 'מחוק',

      is_negative_button: true,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: 'סגור',
    },
    {
      should_show: false,
      initial_open: true,
      key: 'eligibilityOnLoad',
      header: '',
      text: ``,
      is_positive_button: true,
      is_positive_action: false,
      positive_redirect: null,
      positive_button_text: '',

      is_negative_button: true,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: '',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'eligibilityExit',
      header: 'יציאה מבדיקת זכאות',
      text: `האם את/ה בטוח/ה כי ברצונך לצאת מתהליך בדיקת הזכאות? יציאה מהתליך לא תשמור את הפרטים בטופס`,
      is_positive_button: true,
      is_positive_action: false,
      positive_redirect: null,
      positive_button_text: 'המשך בדיקת זכאות',

      is_negative_button: true,
      is_negative_action: false,
      negative_redirect: 'https://www.cua.org.il/',
      negative_button_text: 'יציאה',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'eligibilityConfirm',
      header: 'שים לב!',
      text: ' חשוב לבדוק שכל הפרטים שמולאו עד כה נכונים ומדוייקים ורק אז ללחוץ על בדיקת זכאות',
      is_positive_button: true,
      is_positive_action: true,
      positive_redirect: null,
      positive_button_text: 'להמשך בדיקת זכאות',
      is_negative_button: true,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: 'חזרה לטופס',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'onAppealLoad',
      header: 'תשומת הלב',
      text: `
        יש לעיין בשני המסמכים הבאים:
            <br />
            <a href={'https://static.cua.org.il/public/admin/pdf/1.pdf'}>
              https://static.cua.org.il/public/admin/pdf/1.pdf
            </a>
            <br />
            <a href={'https://static.cua.org.il/public/admin/pdf/2.pdf'}>
              https://static.cua.org.il/public/admin/pdf/2.pdf
            </a>
      `,
      is_positive_button: true,
      is_positive_action: false,
      positive_redirect: null,
      positive_button_text: 'סגור',
      is_negative_button: false,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: '',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'dontForgetToSubmit',
      header: 'שים.י לב!',
      text: ' יש ללחוץ על הגש.י בקשה למטה בסיום מילוי הטופס- ללא לחיצה על הגש.י בקשה, הבקשה לא תוגש. בסיום ההגשה יתקבל מייל המאשר את ההגשה. יש לוודא קבלת מייל זה.',
      is_positive_button: true,
      is_positive_action: false,
      positive_redirect: null,
      positive_button_text: 'סגור',
      is_negative_button: false,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: '',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'dontForgetToSubmitReturnedToRepair',
      header: 'שים.י לב!',
      text: `
                      הבקשה הוחזרה אליך לתיקון:
                <ul>
                  <li>
                    ברשותך 10 ימים ממועד קבלת המייל וה-SMS המודיעים על החזרת
                    הבקשה לתיקון
                  </li>
                  <li>
                    המסמכים הנדרשים לתיקון מסומנים בצהוב, ובאייקון (!) ישנו הסבר
                    מה נדרש לתיקון
                  </li>
                  <li>
                    עליך לעבור על כל העמוד, לאתר את כל השדות הצהובים ולתקן בהתאם
                    למידע המופיע באייקון ה-(!)
                  </li>
                  <li>
                    בטווח ה-10 הימים ניתן לחזור ולתקן את המסמכים. לאחר הגשת
                    התיקונים לא ניתן יהיה לערוך יותר, גם אם לא כל 10 הימים
                    הסתיימו
                  </li>
                  <li>
                    בסיום העריכה, יש להגיש את התיקון בלחיצה על "הגש.י תיקונים"
                  </li>
                </ul>
      `,
      is_positive_button: true,
      is_positive_action: false,
      positive_redirect: null,
      positive_button_text: 'סגור',
      is_negative_button: false,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: '',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'preSubmitRequest',
      header: 'הגש בקשה',
      text: `
                 את.ה עומד.ת להגיש את הבקשה. לאחר הגשתה לא ניתן יותר לערוך, לשנות
            במידת הצורך, להוסיף או למחוק מסמכים או כל מידע אחר שנמסר. אם ברצונך
            להמשיך לערוך את הבקשה, יש ללחוץ על "חזרה לבקשה". אם ברצונך להגיש
            אותה, יש ללחוץ על "הגש.י בקשה". בכל שלב ניתן לחזור ולצפות בנתונים
            באיזור האישי.
            <br />
            יש להתעדכן באיזור האישי באתר המלגה בשבועות הקרובים.
      `,
      is_positive_button: true,
      is_positive_action: true,
      positive_redirect: null,
      positive_button_text: 'הגש.י בקשה',
      is_negative_button: true,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: 'חזרה לבקשה',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'sendBackFromRepair',
      header: 'הגש תיקונים',
      text: 'ניתן לתקן בקשה פעם אחת בלבד. לאחר לחיצה על הגש תיקונים, לא יתאפשר יותר שום תיקון או עריכה. יש לבדוק היטב כי כל הטפסים תקינים ורק אז להגיש',
      is_positive_button: true,
      is_positive_action: true,
      positive_redirect: null,
      positive_button_text: 'הגש תיקונים',
      is_negative_button: true,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: 'יציאה',
    },
    {
      should_show: true,
      initial_open: false,
      key: 'exitRegistration',
      header: 'יציאה מהרשמה למלגה',
      text: 'האם ברצונך לשמור את הבקשה ולצאת? הבקשה לא תימחק וניתן לחזור אליה\n בכל זמן מהאזור האישי',
      is_positive_button: true,
      is_positive_action: true,
      positive_redirect: 'https://www.cua.org.il/',
      positive_button_text: 'כן,שמור וצא',
      is_negative_button: true,
      is_negative_action: false,
      negative_redirect: null,
      negative_button_text: 'סגור',
    },
  ];

  const modals = selectors.useModalWindows();
  const modalData = modals?.find((x) => x.key === modal_key);

  const {
    header = '',
    text = '',
    initial_open = false,
    is_positive_button = false,
    is_negative_button = false,
    is_positive_action = false,
    is_negative_action = false,
    positive_redirect = null,
    negative_redirect = null,
    positive_button_text = '',
    negative_button_text = '',
  } = modalData || {};

  const [isOpen, setIsOpen] = useState(initial_open);

  useEffect(() => {
    setIsOpen(forceOpen);
  }, [forceOpen]);

  useEffect(() => {
    setIsOpen(initial_open);
  }, [initial_open]);

  if (!modalData) {
    return <></>;
  }

  if (!modalData.should_show) {
    return <></>;
  }

  console.log(modalData, isOpen);
  return (
    <Modal
      isModalOpen={isOpen || false}
      header={header}
      modalBody={
        <CUI.Div _modalBodyContainer>
          <CUI.Span _modalBodyHeader _mt24>
            <span
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></span>
          </CUI.Span>
          <CUI.Div _inline>
            {is_positive_button && (
              <CUI.Button
                _btnPrimaryPerach
                _mb16
                _mt24
                _ml16
                onClick={async () => {
                  setIsOpen(false);
                  if (closeModalCallback) {
                    closeModalCallback();
                  }

                  if (positiveAction && is_positive_action) {
                    await new Promise<void>((resolve) => {
                      positiveAction();
                      resolve();
                    });
                  }

                  if (positive_redirect) {
                    window.location.replace(positive_redirect);
                  }
                }}
              >
                {positive_button_text}
              </CUI.Button>
            )}

            {is_negative_button && (
              <CUI.Button
                _btnInfoPerach
                _noMinWidthMob
                _mb16
                _mt24
                onClick={async () => {
                  setIsOpen(false);

                  if (closeModalCallback) {
                    closeModalCallback();
                  }

                  if (negativeAction && is_negative_action) {
                    await new Promise<void>((resolve) => {
                      negativeAction();
                      resolve();
                    });
                  }

                  if (negative_redirect) {
                    window.location.replace(negative_redirect);
                  }
                }}
              >
                {negative_button_text}
              </CUI.Button>
            )}
          </CUI.Div>
        </CUI.Div>
      }
      closeModal={() => {
        setIsOpen(false);
        if (closeModalCallback) {
          closeModalCallback();
        }
      }}
    />
  );
};
