import { SagaIterator } from 'redux-saga';
import { put, call, all, takeEvery } from 'redux-saga/effects';

import {
  getAllRegistrationPeriodsApi,
  getAppSettingsApi,
  getFieldsHelpApi,
  getModalWindowsApi,
  sendMailApi,
} from './api';
import { actions } from './ducks';
import { SettingsResponse } from '../types';
import { loaderModel } from '@entities/loader';
import { composeSaga, passActionPayload } from '@common/store-utils';

/**
 * Load settings
 * @returns {void}
 */
function* loadSettingsSaga(): SagaIterator {
  yield put(loaderModel.actions.setIsLoading(true));

  try {
    const data: SettingsResponse = yield call(getAppSettingsApi);
    const periods = yield call(getAllRegistrationPeriodsApi);
    const fields = yield call(getFieldsHelpApi);
    const modals = yield call(getModalWindowsApi);

    yield put(actions.setRegisterPeriod(data[0].REGISTER_PERIOD));
    yield put(actions.setRegistrationPeriods(periods));
    yield put(actions.setFieldsHelp(fields));
    yield put(actions.setModalWindows(modals));
  } catch (e) {
    console.log('There is error in pulling application settings', e);
  } finally {
    yield put(loaderModel.actions.setIsLoading(false));
  }
}

function* sendMailSaga(text: string): SagaIterator {
  try {
    const periods = yield call(
      sendMailApi,
      'noreply@cua.org.il',
      'anatoly.s@compie.co.il',
      'student problem',
      text
    );
  } catch (e) {
    console.log('Email sending faild', e);
  }
}

/**
 * Watcher
 * @returns {void}
 */
function* watcher(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getSettings, loadSettingsSaga),
    takeEvery(actions.sendMail, composeSaga(sendMailSaga, [passActionPayload])),
  ]);
}

export const sagas = {
  watcher,
};
