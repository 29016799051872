import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faEnvelope,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';

import * as CUI from '@CUI';
import { useActions } from '@common/store-utils/hooks';
import {
  CheckBox,
  CollapseHeader,
  LabelWithPopup,
  OpionalTooltip,
  OptionsInput,
} from '@common/perach-ui';
import { Optional } from '@common/ui';

import { FileInput, OnLoadCallbackType } from '@entities/file';
import { userModel } from '@entities/user';
import { getYearLib } from '@entities/time';

import { useIsUserUnder30 } from '../hooks';
import { stepFourFields, stepThreeFields } from '../fields';
import { actions, selectors } from '../model';
import {
  DisabilityLabels,
  FamilyMembersType,
  MilitaryType,
  OneOrBothParentsType,
} from '../types';
import { config } from '../config';
import { FileUploadFormIncome } from './FileUploadFormIncome';
import { SendBackFromRepairModal } from './SendBackFromRepairModal';
import { DontForgetToPressSubmitModal } from '@features/Registration/Components/DontForgetToPressSubmitModal';
import { ResultModal } from '@features/Registration/Components/ResultModal';
import { statusValue } from '@common/text';
import { PreSubmitRequestModal } from '@features/Registration/Components/PreSubmitRequestModal';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ModalWindow } from '@entities/settings/ModalWindow';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const SpecialExclamationIcon = () => (
  <span className="fa-layers fa-fw ml-8">
    <FontAwesomeIcon
      icon={solid('circle')}
      color={'rgb(0,255,150)'}
      fontSize={16}
    />
    <FontAwesomeIcon icon={faExclamationCircle} fontSize={16} color={'black'} />
  </span>
);

/**
 * Users personal data for Registration
 * @returns React.FC
 * */
export const FilesUploadForm: React.FC<{
  disabledFields?: Record<string, boolean>;
  fieldsToFix?: Record<string, string>;
  isAdminFlow?: boolean;
}> = ({ disabledFields, fieldsToFix, isAdminFlow }) => {
  const [isSendBackModalOpen, setIsSendBackModalOpen] =
    useState<boolean>(false);
  const [isPreSubmitRequestModalOpen, setIsPreSubmitRequestModalOpen] =
    useState<boolean>(false);

  const [isDontForgetToSubmitModalOpen, setIsDontForgetToSubmitModalOpen] =
    useState<boolean>(false);

  const [siblingsArray, setSiblingsArray] = useState<number[]>([]);
  const [isSendEmailToStudentDisabled, setIsSendEmailToStudentDisabled] =
    useState(false);

  const { t } = useTranslation();
  const {
    updateDocuments,
    setIsModalOpen,
    updatePrefillDocuments,
    updateBrothersDocument,
    sendEmailToStudent,
    autoSaveRegistration,
    saveDocumentsData,
    sendDocumentsAfterFixes,
  } = useActions(actions);
  const documents = selectors.useDocuments();
  const prefilled = selectors.usePrefilledDocuments();
  const additionalInfo = selectors.useAdditionalInfo();
  const personalInfo = selectors.usePersonalInfoData();
  const isSponsored = userModel.selectors.useIsSponsored();
  const requestStatus = userModel.selectors.useRequestStatus();

  const isUserUnder30 = useIsUserUnder30();

  const linkBuilder = (documentName: string | undefined) =>
    `${documents?.documentPath}${documentName}` || '#';

  const insert = (arr: any, index: number, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  const initialCollapses = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
  };

  const BOOL_OPTIONS = [
    { value: true, label: t('YES') },
    { value: false, label: t('NO') },
  ];

  const [collapses, setCollapses] =
    useState<Record<string, boolean>>(initialCollapses);

  const toggleCollapse = (id: string) =>
    setCollapses({ ...collapses, [id]: !collapses[id] });

  const onFileSuccess = ({ fileName, fieldName }: OnLoadCallbackType) => {
    updateDocuments({
      changes: { [fieldName]: fileName },
      withNoChangesMark: false,
    });
  };

  const onFileSuccessBrothers = ({
    fileName,
    fieldName,
  }: OnLoadCallbackType) => {
    const inx = Number(fieldName?.split('@@')[1]) || 0;
    updateBrothersDocument({ index: inx, value: fileName });
  };

  const onFileSuccessDisabled = ({
    fileName,
    fieldName,
  }: OnLoadCallbackType) => {
    const inx = Number(fieldName?.split('@@')[1]) || 0;
    updateDocuments({
      changes: {
        disabilityDocuments: documents?.disabilityDocuments.map((el, i) =>
          inx === i ? { ...el, document: fileName } : el
        ),
      },
    });
  };

  const onFileDeleteDisabled = ({ fieldName }: OnLoadCallbackType) => {
    const inx = Number(fieldName?.split('@@')[1]) || 0;
    updateDocuments({
      changes: {
        disabilityDocuments: documents?.disabilityDocuments.map((el, i) =>
          inx === i ? { ...el, document: '' } : el
        ),
      },
    });
    updatePrefillDocuments({
      changes: {
        disabilityDocuments: prefilled?.disabilityDocuments?.map((el, i) =>
          inx === i ? { ...el, document: { url: '', filename: '' } } : el
        ),
      },
    });
    autoSaveRegistration();
  };

  const onFileDeleteBrothers = ({ fieldName }: OnLoadCallbackType) => {
    const inx = Number(fieldName?.split('@@')[1]) || 0;
    const tempArray =
      documents?.approvalStudyBrothersUnder30.map((el, elInx) =>
        inx === elInx ? '' : el
      ) || [];
    updateDocuments({
      changes: {
        approvalStudyBrothersUnder30: tempArray,
      },
    });
    const tempPrefiiledArray =
      prefilled?.approvalStudyBrothersUnder30?.map((el, elInx) =>
        inx === elInx ? { filename: '', url: '' } : el
      ) || [];
    if (!!prefilled?.approvalStudyBrothersUnder30?.[inx]) {
      updatePrefillDocuments({
        changes: {
          approvalStudyBrothersUnder30: tempPrefiiledArray,
        },
      });
    }
    autoSaveRegistration();
  };

  const onFileDelete = ({ fileName, fieldName }: OnLoadCallbackType) => {
    updateDocuments({
      changes: { [fieldName]: '' },
      withNoChangesMark: false,
    });
    updatePrefillDocuments({
      changes: { [fieldName]: { url: '', fileName: '' } },
      withNoChangesMark: false,
    });
    autoSaveRegistration();
  };

  const createSiblingsArray = (num: string) => [...Array(Number(num)).keys()];

  useEffect(() => {
    setSiblingsArray(
      createSiblingsArray(additionalInfo?.siblingsStudentsCount || '0')
    );
  }, [additionalInfo]);

  /** Custom scroll to error feature*/
  const scrollToError = () => {
    // @ts-ignore
    const targetEle: HTMLDivElement = document.getElementsByClassName(
      'invalid-message-item'
    )[0];
    if (!!targetEle) {
      const pos = targetEle?.style?.position;
      const top = targetEle?.style?.top;
      targetEle.style.position = 'relative';
      targetEle.style.top = '-185px';
      targetEle.scrollIntoView({ behavior: 'smooth', block: 'start' });
      targetEle.style.top = top;
      targetEle.style.position = pos;
    }
  };

  useEffect(() => {
    const checkArrays = (): boolean => {
      const parentOneTest =
        additionalInfo?.disabledFamilyMembers?.filter(
          (e) => e.type.value === 'PARENT_ONE'
        ).length ===
        documents?.disabilityDocuments?.map(
          (e) => e.memberType === 'PARENT_ONE'
        );
      const parentTwoTest =
        additionalInfo?.disabledFamilyMembers?.filter(
          (e) => e.type.value === 'PARENT_TWO'
        ).length ===
        documents?.disabilityDocuments?.map(
          (e) => e.memberType === 'PARENT_TWO'
        );
      const brothersTest =
        additionalInfo?.disabledFamilyMembers?.filter(
          (e) => e.type.value === 'BROTHER_SISTER'
        ).length ===
        documents?.disabilityDocuments?.map(
          (e) => e.memberType === 'BROTHER_SISTER'
        );
      const spouseTest =
        additionalInfo?.disabledFamilyMembers?.filter(
          (e) => e.type.value === 'SPOUSE'
        ).length ===
        documents?.disabilityDocuments?.map((e) => e.memberType === 'SPOUSE');
      const candidateTest =
        additionalInfo?.disabledFamilyMembers?.filter(
          (e) => e.type.value === 'CANDIDATE'
        ).length ===
        documents?.disabilityDocuments?.map(
          (e) => e.memberType === 'CANDIDATE'
        );
      return [
        parentOneTest,
        parentTwoTest,
        brothersTest,
        spouseTest,
        candidateTest,
      ].every(Boolean);
    };

    const condition =
      !!additionalInfo?.disabledFamilyMembers &&
      !!additionalInfo?.disabledFamilyMembers?.length &&
      (additionalInfo?.disabledFamilyMembers?.length !==
        documents?.disabilityDocuments?.length ||
        !documents?.disabilityDocuments?.length ||
        !checkArrays());

    if (condition) {
      const disabledFamilyTemp: any = [];
      let prevType = '';
      let nextType = '';
      let count = 0;
      additionalInfo?.disabledFamilyMembers.map((el, index) => {
        nextType = el.type.value || '';
        count = prevType === nextType ? count + 1 : 0;
        prevType = el.type.value || '';
        return el.type.value !== undefined && el.percent !== ''
          ? disabledFamilyTemp.push({
              memberType: el.type.value,
              document:
                documents?.disabilityDocuments?.filter(
                  (e) => e?.memberType === el?.type?.value
                )?.[count]?.document || '',
            })
          : null;
      });

      updateDocuments({
        changes: {
          disabilityDocuments: [...disabledFamilyTemp],
        },
        withNoChangesMark: true,
      });
    }
  }, [additionalInfo]);

  const onSendEmailToStudentClick = () => {
    sendEmailToStudent();
    setIsSendEmailToStudentDisabled(true);
  };

  const isSubmitDisabled =
    !documents?.approveIncomes || !documents?.declarationCorrectnessInformation;

  useEffect(() => {
    if (!requestStatus || requestStatus === statusValue.REPAIR_BACK_FOR) {
      setIsDontForgetToSubmitModalOpen(true);
    }
  }, [requestStatus, setIsDontForgetToSubmitModalOpen]);

  return (
    <>
      <ScrollToTop />
      <div className="form-container ph-25">
        <Row className="justify-content-between mt-34">
          <Col className="col-12 col-md-8 pl-0 mb-40">
            <span className="info-text pl-0">
              {t('SEND_DOC_TO_EMAIL_INFO')}
            </span>
          </Col>
          <Col className="col-auto">
            <CUI.Button
              _dBlock
              _signInButton
              _thikButonText
              _btnPrimaryPerach
              isDisabled={isSendEmailToStudentDisabled}
              onClick={() => onSendEmailToStudentClick()}
            >
              <span>
                {isSendEmailToStudentDisabled
                  ? t('EMAIL_SENT_TO_STUDENT')
                  : t('SEND_TO_EMAIL')}
                <FontAwesomeIcon icon={faEnvelope} className="mr-12" />
              </span>
            </CUI.Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <CUI.Div _formInfoBox _inline>
              <FontAwesomeIcon icon={faCircleExclamation} className="ml-16" />
              <span>{t('FILE_INFO_BOX')}</span>
            </CUI.Div>
          </Col>
        </Row>

        {/*Applicant*/}
        <CollapseHeader
          id="1"
          isOpen={collapses['1']}
          title={t('ID_APLICANT')}
          toggle={() => toggleCollapse('1')}
        />
        <Collapse isOpen={collapses['1']}>
          <Row className="g-2 gx-5">
            <Col className="col-12 mb-40">
              <LabelWithPopup
                label={t('IS_ID_BIOMETRIC')}
                helpKey={stepFourFields.isIdentityBiometric}
              />
              <OptionsInput
                options={BOOL_OPTIONS}
                isDisabled={
                  !!disabledFields?.[stepFourFields.isIdentityBiometric]
                }
                value={!!documents?.isIdentityBiometric}
                onChange={(val) =>
                  updateDocuments({ changes: { isIdentityBiometric: val } })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <FileInput
                isFixesNeed={!!fieldsToFix?.[stepFourFields.frontIdentity]}
                fixesText={fieldsToFix?.[stepFourFields.frontIdentity]}
                isDisabled={!!disabledFields?.[stepFourFields.frontIdentity]}
                label={t('FRONT_SIDE_PHOTO')}
                name={stepFourFields.frontIdentity}
                form={config.forms.documents}
                onLoad={onFileSuccess}
                onDelete={onFileDelete}
                link={linkBuilder(documents?.frontIdentity)}
                fileName={documents?.frontIdentity}
                prefilled={prefilled?.frontIdentity}
                isRequired
                helpText={t('DOCUMENTS.HELP.FRONT_IDENTITY')}
              />
            </Col>
          </Row>
          <Optional when={!!documents?.isIdentityBiometric}>
            <Row>
              <Col className="col-12">
                <FileInput
                  isFixesNeed={!!fieldsToFix?.[stepFourFields.backIdentity]}
                  fixesText={fieldsToFix?.[stepFourFields.backIdentity]}
                  label={t('BACK_SIDE_PHOTO')}
                  name={stepFourFields.backIdentity}
                  isDisabled={!!disabledFields?.[stepFourFields.backIdentity]}
                  form={config.forms.documents}
                  onLoad={onFileSuccess}
                  onDelete={onFileDelete}
                  link={linkBuilder(documents?.backIdentity)}
                  fileName={documents?.backIdentity}
                  prefilled={prefilled?.backIdentity}
                  isRequired
                  helpText={t('DOCUMENTS.HELP.BACK_IDENTITY')}
                />
              </Col>
            </Row>
          </Optional>
          <Row>
            <Col className="col-12">
              <FileInput
                isFixesNeed={!!fieldsToFix?.[stepFourFields.attachmentIdentity]}
                fixesText={fieldsToFix?.[stepFourFields.attachmentIdentity]}
                label={t('APPENDIX_PHOTO')}
                name={stepFourFields.attachmentIdentity}
                isDisabled={
                  !!disabledFields?.[stepFourFields.attachmentIdentity]
                }
                form={config.forms.documents}
                onLoad={onFileSuccess}
                onDelete={onFileDelete}
                link={linkBuilder(documents?.attachmentIdentity)}
                fileName={documents?.attachmentIdentity}
                prefilled={prefilled?.attachmentIdentity}
                isRequired
                helpText={t('DOCUMENTS.HELP.ATTACHMENT_IDENTITY')}
              />
            </Col>
          </Row>
        </Collapse>

        <Optional when={isUserUnder30}>
          {/*Paren ONE*/}
          <CollapseHeader
            id="2"
            isOpen={collapses['2']}
            title={t('FIRST_PARENT_ID')}
            toggle={() => toggleCollapse('2')}
          />
          <Collapse isOpen={collapses['2']}>
            <Optional
              when={
                (!additionalInfo?.isCutOfFromParents &&
                  !additionalInfo?.isOrphan) ||
                (!additionalInfo?.isOrphan &&
                  additionalInfo?.cutOfFromParentsCount.value ===
                    OneOrBothParentsType.ONE) ||
                (!additionalInfo?.isCutOfFromParents &&
                  additionalInfo?.orphanOfParentsCount.value ===
                    OneOrBothParentsType.ONE)
              }
            >
              <Row className="g-2 gx-5">
                <Col className="col-12 mb-40">
                  <LabelWithPopup
                    label={t('IS_ID_BIOMETRIC')}
                    helpKey={stepFourFields.parentOneIsIdentityBiometric}
                  />
                  <OptionsInput
                    options={BOOL_OPTIONS}
                    isDisabled={
                      !!disabledFields?.[
                        stepFourFields.parentOneIsIdentityBiometric
                      ]
                    }
                    value={!!documents?.parentOneIsIdentityBiometric}
                    onChange={(val) =>
                      updateDocuments({
                        changes: { parentOneIsIdentityBiometric: val },
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <FileInput
                    isFixesNeed={
                      !!fieldsToFix?.[stepFourFields.parentOneFrontIdentity]
                    }
                    fixesText={
                      fieldsToFix?.[stepFourFields.parentOneFrontIdentity]
                    }
                    label={t('FRONT_SIDE_PHOTO')}
                    name={stepFourFields.parentOneFrontIdentity}
                    isDisabled={
                      !!disabledFields?.[stepFourFields.parentOneFrontIdentity]
                    }
                    form={config.forms.documents}
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    isRequired
                    prefilled={prefilled?.parentOneFrontIdentity}
                    helpText={t('DOCUMENTS.HELP.FRONT_IDENTITY')}
                  />
                </Col>
              </Row>
              <Optional when={!!documents?.parentOneIsIdentityBiometric}>
                <Row>
                  <Col className="col-12">
                    <FileInput
                      isFixesNeed={
                        !!fieldsToFix?.[stepFourFields.parentOneBackIdentity]
                      }
                      fixesText={
                        fieldsToFix?.[stepFourFields.parentOneBackIdentity]
                      }
                      label={t('BACK_SIDE_PHOTO')}
                      name={stepFourFields.parentOneBackIdentity}
                      isDisabled={
                        !!disabledFields?.[stepFourFields.parentOneBackIdentity]
                      }
                      form={config.forms.documents}
                      onLoad={onFileSuccess}
                      onDelete={onFileDelete}
                      prefilled={prefilled?.parentOneBackIdentity}
                      isRequired
                      helpText={t('DOCUMENTS.HELP.BACK_IDENTITY')}
                    />
                  </Col>
                </Row>
              </Optional>
              <Row>
                <Col className="col-12">
                  <FileInput
                    isFixesNeed={
                      !!fieldsToFix?.[
                        stepFourFields.parentOneAttachmentIdentity
                      ]
                    }
                    fixesText={
                      fieldsToFix?.[stepFourFields.parentOneAttachmentIdentity]
                    }
                    label={t('APPENDIX_PHOTO')}
                    name={stepFourFields.parentOneAttachmentIdentity}
                    isDisabled={
                      !!disabledFields?.[
                        stepFourFields.parentOneAttachmentIdentity
                      ]
                    }
                    form={config.forms.documents}
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    prefilled={prefilled?.parentOneAttachmentIdentity}
                    isRequired
                    helpText={t('DOCUMENTS.HELP.ATTACHMENT_IDENTITY')}
                  />
                </Col>
              </Row>
            </Optional>
            <Optional
              when={
                additionalInfo?.orphanOfParentsCount.value ===
                  OneOrBothParentsType.BOTH ||
                additionalInfo?.cutOfFromParentsCount.value ===
                  OneOrBothParentsType.BOTH ||
                (additionalInfo?.cutOfFromParentsCount.value ===
                  OneOrBothParentsType.ONE &&
                  additionalInfo?.orphanOfParentsCount.value ===
                    OneOrBothParentsType.ONE)
              }
            >
              <Row>
                <CUI.Span
                  _txtBold
                  _txtDanger
                  shouldRender={!!additionalInfo?.isCutOfFromParents}
                >
                  <SpecialExclamationIcon /> להורדת התצהיר לניתוק קשר,
                  <a
                    href={'https://www.cua.org.il/assets/tacir.pdf'}
                    target="_blank"
                  >
                    יש ללחוץ כאן
                  </a>
                </CUI.Span>
                <Col className="col-12">
                  <FileInput
                    isFixesNeed={
                      !!fieldsToFix?.[
                        stepFourFields.parentOneDeathCertificate
                      ] ||
                      !!fieldsToFix?.[
                        stepFourFields.parentOneDisconnectPermission
                      ]
                    }
                    fixesText={
                      fieldsToFix?.[stepFourFields.parentOneDeathCertificate] ||
                      fieldsToFix?.[
                        stepFourFields.parentOneDisconnectPermission
                      ]
                    }
                    label={
                      !!additionalInfo?.isCutOfFromParents
                        ? 'תצהיר בפני עורך דין'
                        : 'תעודת פטירה'
                    }
                    name={stepFourFields.parentOneDeathCertificate}
                    isDisabled={
                      !!disabledFields?.[
                        stepFourFields.parentOneDeathCertificate
                      ] &&
                      !!disabledFields?.[
                        stepFourFields.parentOneDisconnectPermission
                      ]
                    }
                    form={config.forms.documents}
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    prefilled={prefilled?.parentOneDeathCertificate}
                    isRequired
                    helpText={t('DOCUMENTS.HELP.DEATH_IDENTITY')}
                  />
                </Col>
              </Row>
            </Optional>
          </Collapse>

          {/*Parent TWO*/}
          <CollapseHeader
            id="3"
            isOpen={collapses['3']}
            title={t('SECOND_PARENT_ID')}
            toggle={() => toggleCollapse('3')}
          />
          <Collapse isOpen={collapses['3']}>
            <Optional
              when={
                !additionalInfo?.isOrphan && !additionalInfo?.isCutOfFromParents
              }
            >
              <Row className="g-2 gx-5">
                <Col className="col-12 mb-40">
                  <LabelWithPopup
                    label={t('IS_ID_BIOMETRIC')}
                    helpKey={stepFourFields.parentTwoIsIdentityBiometric}
                  />
                  <OptionsInput
                    options={BOOL_OPTIONS}
                    isDisabled={
                      !!disabledFields?.[
                        stepFourFields.parentTwoIsIdentityBiometric
                      ]
                    }
                    value={!!documents?.parentTwoIsIdentityBiometric}
                    onChange={(val) =>
                      updateDocuments({
                        changes: { parentTwoIsIdentityBiometric: val },
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <FileInput
                    label={t('FRONT_SIDE_PHOTO')}
                    isFixesNeed={
                      !!fieldsToFix?.[stepFourFields.parentTwoFrontIdentity]
                    }
                    fixesText={
                      fieldsToFix?.[stepFourFields.parentTwoFrontIdentity]
                    }
                    name={stepFourFields.parentTwoFrontIdentity}
                    isDisabled={
                      !!disabledFields?.[stepFourFields.parentTwoFrontIdentity]
                    }
                    form={config.forms.documents}
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    prefilled={prefilled?.parentTwoFrontIdentity}
                    isRequired
                    helpText={t('DOCUMENTS.HELP.FRONT_IDENTITY')}
                  />
                </Col>
              </Row>
              <Optional when={!!documents?.parentTwoIsIdentityBiometric}>
                <Row>
                  <Col className="col-12">
                    <FileInput
                      label={t('BACK_SIDE_PHOTO')}
                      isFixesNeed={
                        !!fieldsToFix?.[stepFourFields.parentTwoBackIdentity]
                      }
                      fixesText={
                        fieldsToFix?.[stepFourFields.parentTwoBackIdentity]
                      }
                      name={stepFourFields.parentTwoBackIdentity}
                      isDisabled={
                        !!disabledFields?.[stepFourFields.parentTwoBackIdentity]
                      }
                      form={config.forms.documents}
                      onLoad={onFileSuccess}
                      onDelete={onFileDelete}
                      prefilled={prefilled?.parentTwoBackIdentity}
                      isRequired
                      helpText={t('DOCUMENTS.HELP.BACK_IDENTITY')}
                    />
                  </Col>
                </Row>
              </Optional>
              <Row>
                <Col className="col-12">
                  <FileInput
                    label={t('APPENDIX_PHOTO')}
                    isFixesNeed={
                      !!fieldsToFix?.[
                        stepFourFields.parentTwoAttachmentIdentity
                      ]
                    }
                    fixesText={
                      fieldsToFix?.[stepFourFields.parentTwoAttachmentIdentity]
                    }
                    name={stepFourFields.parentTwoAttachmentIdentity}
                    isDisabled={
                      !!disabledFields?.[
                        stepFourFields.parentTwoAttachmentIdentity
                      ]
                    }
                    form={config.forms.documents}
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    prefilled={prefilled?.parentTwoAttachmentIdentity}
                    isRequired
                    helpText={t('DOCUMENTS.HELP.ATTACHMENT_IDENTITY')}
                  />
                </Col>
              </Row>
            </Optional>
            <Optional
              when={
                !!additionalInfo?.isOrphan ||
                !!additionalInfo?.isCutOfFromParents
              }
            >
              <Row>
                <CUI.Span
                  _txtBold
                  _txtDanger
                  shouldRender={
                    !!additionalInfo?.isCutOfFromParents &&
                    (additionalInfo?.cutOfFromParentsCount.value ===
                      OneOrBothParentsType.BOTH ||
                      !additionalInfo?.isOrphan)
                  }
                >
                  <SpecialExclamationIcon />
                  להורדת התצהיר לניתוק קשר,
                  <a
                    href={'https://www.cua.org.il/assets/tacir.pdf'}
                    target="_blank"
                  >
                    יש ללחוץ כאן
                  </a>
                </CUI.Span>
                <Col className="col-12">
                  <FileInput
                    label={
                      !!additionalInfo?.isCutOfFromParents &&
                      (additionalInfo?.cutOfFromParentsCount.value ===
                        OneOrBothParentsType.BOTH ||
                        !additionalInfo?.isOrphan)
                        ? 'תצהיר בפני עורך דין'
                        : 'תעודת פטירה'
                    }
                    name={stepFourFields.parentTwoDeathCertificate}
                    isFixesNeed={
                      !!fieldsToFix?.[
                        stepFourFields.parentTwoDeathCertificate
                      ] ||
                      !!fieldsToFix?.[
                        stepFourFields.parentTwoDisconnectPermission
                      ]
                    }
                    fixesText={
                      fieldsToFix?.[stepFourFields.parentTwoDeathCertificate] ||
                      fieldsToFix?.[
                        stepFourFields.parentTwoDisconnectPermission
                      ]
                    }
                    isDisabled={
                      !!disabledFields?.[
                        stepFourFields.parentTwoDeathCertificate
                      ] &&
                      !!disabledFields?.[
                        stepFourFields.parentTwoDisconnectPermission
                      ]
                    }
                    form={config.forms.documents}
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    prefilled={prefilled?.parentTwoDeathCertificate}
                    isRequired
                    helpText={t('DOCUMENTS.HELP.DEATH_IDENTITY')}
                  />
                </Col>
              </Row>
            </Optional>
          </Collapse>
        </Optional>
        {/* STUDIES */}
        <CollapseHeader
          id="4"
          isOpen={collapses['4']}
          title={t('STUDIES')}
          toggle={() => toggleCollapse('4')}
        />
        <Collapse isOpen={collapses['4']}>
          <Row>
            <Col className="col-12">
              <FileInput
                label={`${t(
                  'FIELD_STUDIES_LICENSE'
                )} ${getYearLib.getCurrentHebrewYear()}`}
                name={stepFourFields.confirmationStudies}
                isFixesNeed={
                  !!fieldsToFix?.[stepFourFields.confirmationStudies]
                }
                fixesText={fieldsToFix?.[stepFourFields.confirmationStudies]}
                isDisabled={
                  !!disabledFields?.[stepFourFields.confirmationStudies]
                }
                onLoad={onFileSuccess}
                onDelete={onFileDelete}
                form={config.forms.documents}
                prefilled={prefilled?.confirmationStudies}
                isRequired
                helpText={t('DOCUMENTS.HELP.CONFIRMATION_STUDIES')}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <FileInput
                label={`${t(
                  'DOCUMENTS.LABELS.TUITION'
                )} ${getYearLib.getCurrentHebrewYear()}`}
                isFixesNeed={
                  !!fieldsToFix?.[stepFourFields.approvalAnnualTuitionFees]
                }
                fixesText={
                  fieldsToFix?.[stepFourFields.approvalAnnualTuitionFees]
                }
                name={stepFourFields.approvalAnnualTuitionFees}
                isDisabled={
                  !!disabledFields?.[stepFourFields.approvalAnnualTuitionFees]
                }
                onLoad={onFileSuccess}
                onDelete={onFileDelete}
                form={config.forms.documents}
                prefilled={prefilled?.approvalAnnualTuitionFees}
                isRequired
                helpText={t('DOCUMENTS.HELP.TUITION')}
              />
            </Col>
          </Row>
          {
            //!!additionalInfo?.siblingsStudentsCount &&
            siblingsArray.map(
              //[0,1,2].map(
              (e, inx) => (
                <Row>
                  <Col className="col-12">
                    <FileInput
                      label={`${t('FIELD_SCHEDULE_STUDIES_LICENSE_BROTHER_1', {
                        n: inx + 1,
                      })} ${getYearLib.getCurrentHebrewYear()}`}
                      name={`${stepFourFields.brother}@@${inx}`}
                      isFixesNeed={
                        !!fieldsToFix?.[`${stepFourFields.brother}@@${inx}`]
                      }
                      fixesText={
                        fieldsToFix?.[`${stepFourFields.brother}@@${inx}`]
                      }
                      isDisabled={
                        !!disabledFields?.[stepFourFields.brother] &&
                        !fieldsToFix?.[`${stepFourFields.brother}@@${inx}`]
                      }
                      onLoad={onFileSuccessBrothers}
                      onDelete={onFileDeleteBrothers}
                      form={config.forms.documents}
                      prefilled={prefilled?.approvalStudyBrothersUnder30?.[inx]}
                      isRequired
                      helpText={t('DOCUMENTS.HELP.STUDIES_LICENSE_BROTHER_1')}
                    />
                  </Col>
                </Row>
              )
            )
          }
        </Collapse>

        {/* BANK DETAILS */}
        <Optional when={!isSponsored}>
          <CollapseHeader
            id="5"
            isOpen={collapses['5']}
            title={t('COLLAPSE_HEADER_BANK_DETAILS')}
            toggle={() => toggleCollapse('5')}
          />
          <Collapse isOpen={collapses['5']}>
            <Row>
              <Col className="col-12">
                <FileInput
                  label={t('FIELD_BANK_DETAILS')}
                  name={stepFourFields.bankAccountReference}
                  isFixesNeed={
                    !!fieldsToFix?.[stepFourFields.bankAccountReference]
                  }
                  fixesText={fieldsToFix?.[stepFourFields.bankAccountReference]}
                  isDisabled={
                    !!disabledFields?.[stepFourFields.bankAccountReference]
                  }
                  onLoad={onFileSuccess}
                  onDelete={onFileDelete}
                  form={config.forms.documents}
                  prefilled={prefilled?.bankAccountReference}
                  isRequired
                  helpText={t('DOCUMENTS.HELP.BANK_CONFIRMATION')}
                />
              </Col>
            </Row>
          </Collapse>
        </Optional>

        {/* INCOME */}
        <CollapseHeader
          id="6"
          isOpen={collapses['6']}
          title={t('COLLAPSE_HEADER_INCOME')}
          toggle={() => toggleCollapse('6')}
        />
        <Collapse isOpen={collapses['6']}>
          <FileUploadFormIncome
            disabledFields={disabledFields}
            fieldsToFix={fieldsToFix}
          />
        </Collapse>

        {/* MILLITARY */}
        <Optional
          when={
            personalInfo?.militaryServiceType?.value !==
              MilitaryType.NOT_SERVED || personalInfo?.isReservist === true
          }
        >
          <CollapseHeader
            id="7"
            isOpen={collapses['7']}
            title={t('COLLAPSE_HEADER_MILITARY_SERVICE')}
            toggle={() => toggleCollapse('7')}
          />
          <Collapse isOpen={collapses['7']}>
            <Optional
              when={
                personalInfo?.militaryServiceType?.value !==
                MilitaryType.NOT_SERVED
              }
            >
              <Row>
                <Col className="col-12">
                  <FileInput
                    label={t('FIELD_DEMOBILISATION_DOCUMENT')}
                    name={stepFourFields.armyTypeDocument}
                    isFixesNeed={
                      !!fieldsToFix?.[stepFourFields.armyTypeDocument]
                    }
                    fixesText={fieldsToFix?.[stepFourFields.armyTypeDocument]}
                    isDisabled={
                      !!disabledFields?.[stepFourFields.armyTypeDocument]
                    }
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    form={config.forms.documents}
                    prefilled={prefilled?.armyTypeDocument}
                    isRequired
                    helpText={t('DOCUMENTS.HELP.ARMY')}
                  />
                </Col>
              </Row>
            </Optional>
            <Optional when={personalInfo?.isReservist === true}>
              <Row>
                <Col className="col-12">
                  <FileInput
                    label={'אישור על שירות המילואים'}
                    name={stepFourFields.reservistDocument}
                    isFixesNeed={
                      !!fieldsToFix?.[stepFourFields.reservistDocument]
                    }
                    fixesText={fieldsToFix?.[stepFourFields.reservistDocument]}
                    isDisabled={
                      !!disabledFields?.[stepFourFields.reservistDocument]
                    }
                    onLoad={onFileSuccess}
                    onDelete={onFileDelete}
                    form={config.forms.documents}
                    prefilled={prefilled?.reservistDocument}
                    isRequired
                    helpText={t('DOCUMENTS.HELP.ARMY')}
                  />
                </Col>
              </Row>
            </Optional>
          </Collapse>
        </Optional>

        <Optional when={!!additionalInfo?.isNewcomer}>
          <CollapseHeader
            id="10"
            isOpen={collapses['10']}
            title={t('COLLAPSE_HEADER_NEWCOMMER')}
            toggle={() => toggleCollapse('10')}
          />
          <Collapse isOpen={collapses['10']}>
            <Row>
              <Col className="col-12">
                <FileInput
                  label={t('DOCUMENTS.LABELS.NEWCOMMER_DOCUMENT')}
                  name={stepFourFields.newcomerDocument}
                  isFixesNeed={!!fieldsToFix?.[stepFourFields.newcomerDocument]}
                  fixesText={fieldsToFix?.[stepFourFields.newcomerDocument]}
                  isDisabled={
                    !!disabledFields?.[stepFourFields.newcomerDocument]
                  }
                  onLoad={onFileSuccess}
                  onDelete={onFileDelete}
                  form={config.forms.documents}
                  prefilled={prefilled?.newcomerDocument}
                  isRequired
                  helpText={t('DOCUMENTS.HELP.NEWCOMMER')}
                />
              </Col>
            </Row>
          </Collapse>
        </Optional>

        <Optional when={!!additionalInfo?.isSingleParent}>
          <CollapseHeader
            id="8"
            isOpen={collapses['8']}
            title={t('COLLAPSE_HEADER_FAMILY_STATUS')}
            toggle={() => toggleCollapse('8')}
          />
          <Collapse isOpen={collapses['8']}>
            <Row>
              <Col className="col-12">
                <FileInput
                  label={`${t('FIELD_SINGLE_PARENT_APPROVAL')}`}
                  name={stepFourFields.singleParentApproval}
                  isFixesNeed={
                    !!fieldsToFix?.[stepFourFields.singleParentApproval]
                  }
                  fixesText={fieldsToFix?.[stepFourFields.singleParentApproval]}
                  isDisabled={
                    !!disabledFields?.[stepFourFields.singleParentApproval]
                  }
                  onLoad={onFileSuccess}
                  onDelete={onFileDelete}
                  form={config.forms.documents}
                  isRequired
                  helpText={t('DOCUMENTS.HELP.SINGLE_PARENT_APPROVAL')}
                  prefilled={prefilled?.singleParentApproval}
                />
              </Col>
            </Row>
          </Collapse>
        </Optional>

        <Optional when={!!additionalInfo?.isDisabledFamilyMembers}>
          <CollapseHeader
            id="9"
            isOpen={collapses['9']}
            title={t('COLLAPSE_HEADER_DISABILITY')}
            toggle={() => toggleCollapse('9')}
          />
          <Collapse isOpen={collapses['9']}>
            {!!documents?.disabilityDocuments?.length &&
              documents?.disabilityDocuments.map((el, inx) => (
                <Row key={inx}>
                  <Col className="col-12">
                    <FileInput
                      label={`${t(
                        `DOCUMENTS.DISABILITY_LABELS.${
                          el.memberType as DisabilityLabels
                        }`
                      )}`}
                      name={`${stepFourFields.disabledFamily}@@${inx}`}
                      isDisabled={
                        !!disabledFields?.[stepFourFields.disabledFamily] &&
                        !fieldsToFix?.[
                          `${stepFourFields.disabledFamily}@@${inx}`
                        ]
                      }
                      isFixesNeed={
                        !!fieldsToFix?.[
                          `${stepFourFields.disabledFamily}@@${inx}`
                        ]
                      }
                      fixesText={
                        fieldsToFix?.[
                          `${stepFourFields.disabledFamily}@@${inx}`
                        ]
                      }
                      form={config.forms.documents}
                      onLoad={onFileSuccessDisabled}
                      onDelete={onFileDeleteDisabled}
                      prefilled={
                        prefilled?.disabilityDocuments?.[inx]?.document
                      }
                      isRequired
                      helpText={t('DOCUMENTS.HELP.DISABILITY')}
                    />
                  </Col>
                </Row>
              ))}
          </Collapse>
        </Optional>
        <Row>
          <Col className="col-12">
            <FileInput
              label={t('FIELD_ADDITIONAL')}
              name={stepFourFields.additionalDocument}
              isFixesNeed={!!fieldsToFix?.[stepFourFields.additionalDocument]}
              fixesText={fieldsToFix?.[stepFourFields.additionalDocument]}
              isDisabled={!!disabledFields?.[stepFourFields.additionalDocument]}
              onLoad={onFileSuccess}
              onDelete={onFileDelete}
              form={config.forms.documents}
              prefilled={prefilled?.additionalDocument}
              helpText={t('ADDITIONAL_FIELD_TOOLTIP')}
            />
          </Col>
        </Row>

        <Row className="g-2 gx-5">
          <Col className="col-12">
            {/*<CheckBox*/}
            {/*  value={documents?.declarationCorrectnessInformation}*/}
            {/*  name="declarationCorrectnessInformation"*/}
            {/*  form={config.forms.documents}*/}
            {/*  checked={!!documents?.declarationCorrectnessInformation}*/}
            {/*  onChange={() =>*/}
            {/*    updateDocuments({*/}
            {/*      changes: {*/}
            {/*        declarationCorrectnessInformation:*/}
            {/*          !documents?.declarationCorrectnessInformation,*/}
            {/*      },*/}
            {/*    })*/}
            {/*  }*/}
            {/*>*/}
            {/*{t('CHECKBOX_DOCUMENTS_LABEL')}*/}
            <strong>
              <ul>
                <li>
                  אני מאשר.ת כי קראתי את הקריטריונים המפורסמים באתר המלגה, הבנתי
                  ואני מסכים.ה לתוכנם.
                </li>
                <li>
                  ידוע לי כי לאחר הגשת הבקשה לא אוכל לערוך בה שינויים, להעלות או
                  להסיר מסמכים והבקשה תעבור לבחינת זכאותי למלגה. יחד עם זאת,
                  ידוע לי כי אוכל לחזור ולצפות בבקשה ובמסמכים שהוגשו.
                </li>
                <li>
                  אני מצהיר.ה שאני מודע.ת שעלי ללמוד בשני הסמסטרים (סמסטר א'
                  ו-ב') בהיקף של לפחות 60%, או לפחות 4 קורסים אקדמיים באו"פ.
                  ידוע לי כי הצהרה זו תיבדק מול מוסד הלימודים, ובמידה ולא תאושר
                  על ידו, הבקשה עלולה להיפסל.
                </li>
                <li>
                  אני מצהיר.ה שאני מודע.ת שעליי להיות סטודנט.ית פעיל.ה אקדמית
                  בסמסטר ב'. ידוע לי שאם לא אהיה סטודנט.ית פעיל.ה אקדמית לפי
                  תקנון מוסד הלימודים שלי בסמסטר ב'- לא אהיה זכאי.ת למלגה.
                </li>
                <li>
                  ידוע לי כי הגשת הבקשה למלגה אינה מזכה במלגה כלשהי, או בחלק
                  ממנה, ובקשתי תבחן אל מול הקריטריונים ושאר הבקשות לשם קביעת
                  זכאותי, אם בכלל.
                </li>
                <li>
                  אני מאשר.ת העברת כל מידע והמסמכים שמסרתי למלגת מיל-go! למוסד
                  הלימודים בו אני לומד.ת, למל"ג ומה"ט.
                </li>
                <li>
                  הנני מסכים.ה ומאשר.ת בזאת במפורש כי כל המידע והמסמכים שנמסרו
                  על ידי למועצה להשכלה גבוהה/הועדה לתכנון ולתקצוב במסגרת בקשתי
                  למלגה:
                  <ul>
                    <li>יישמרו במאגר מידע של המועצה להשכלה גבוהה/ות"ת;</li>
                    <li>
                      נמסר מרצוני החופשי ובהסכמתי, מבלי שמוטלת עליי חובה חוקית
                      כלשהי לעשות כן;
                    </li>
                    <li>
                      המועצה להשכלה גבוהה/הועדה לתכנון ותקצוב/מה"ט יהיו רשאים
                      להשתמש במידע והמסמכים שהתקבלו ממני למטרות שונות, לרבות
                      פנייה בדיוור ישיר, או פנייה ישירה אלי במגוון אמצעי תקשורת.
                    </li>
                  </ul>
                </li>
                <li>
                  כל הפרטים שמסרתי בבקשה וכל ההצהרות שהצהרתי הם נכונים ומלאים.
                  ידוע לי, שמסירת פרטים לא נכונים או העלמת נתונים מהווה עבירה על
                  החוק, ועלולה להביא לפסילת הבקשה.
                </li>
              </ul>
            </strong>
            {/*</CheckBox>*/}
          </Col>
        </Row>
        <Row className="g-2 gx-5 mb-40">
          <Col className="col-12">
            <CheckBox
              value={documents?.declarationCorrectnessInformation}
              name="declarationCorrectnessInformation"
              form={config.forms.documents}
              checked={!!documents?.declarationCorrectnessInformation}
              onChange={() =>
                updateDocuments({
                  changes: {
                    declarationCorrectnessInformation:
                      !documents?.declarationCorrectnessInformation,
                  },
                })
              }
            >
              <CUI.Span _fs17>
                {
                  'אני מאשר.ת שקראתי את הכתוב מעלה ואני מסכימ.ה לנאמר לעיל (חובה לאשר להמשך התהליך)'
                }
              </CUI.Span>
            </CheckBox>
          </Col>
        </Row>
        <Row g-2 gx-5 mb-40>
          <Col className="col-12 col-md-9 mb-40">
            <OpionalTooltip
              option={isSubmitDisabled}
              text={t('DISABLE_BUTTON_STEP_FOUR_TOOLTIP')}
            >
              <CUI.Button
                _dBlock
                _w100
                _signInButton
                _btnPrimaryPerach
                form={config.forms.documents}
                onClick={() => {
                  !!Object.keys(fieldsToFix || {}).length
                    ? setIsSendBackModalOpen(true)
                    : setIsPreSubmitRequestModalOpen(true);
                }}
                isDisabled={
                  isSubmitDisabled ||
                  !!disabledFields?.[stepFourFields.buttonSubmit]
                }
                shouldScrollToInvalidFields
                scrollOffset={100}
                onValidationFail={() => setTimeout(() => scrollToError(), 100)}
              >
                {!!Object.keys(fieldsToFix || {}).length
                  ? 'הגש.י תיקונים'
                  : t('SEND_REQUEST')}
              </CUI.Button>
            </OpionalTooltip>
          </Col>
          <Col className="col-12 col-md-3 mb-40">
            <CUI.Button
              _dBlock
              _w100
              _signInButton
              _btnInfoPerach
              isDisabled={!!disabledFields?.[stepFourFields.buttonSave]}
              onClick={() =>
                !isAdminFlow ? setIsModalOpen(true) : autoSaveRegistration()
              }
            >
              {t('SAVE_AND_CLOSE')}
            </CUI.Button>
          </Col>
        </Row>
      </div>
      {/*<SendBackFromRepairModal*/}
      {/*  isOpen={isSendBackModalOpen}*/}
      {/*  setIsOpen={setIsSendBackModalOpen}*/}
      {/*/>*/}
      <ModalWindow
        modal_key={'sendBackFromRepair'}
        forceOpen={isSendBackModalOpen}
        positiveAction={sendDocumentsAfterFixes}
      />
      {/*<PreSubmitRequestModal*/}
      {/*  isOpen={isPreSubmitRequestModalOpen}*/}
      {/*  setIsOpen={setIsPreSubmitRequestModalOpen}*/}
      {/*/>*/}
      <ModalWindow
        modal_key={'preSubmitRequest'}
        forceOpen={isPreSubmitRequestModalOpen}
        positiveAction={saveDocumentsData}
      />
      {/*<DontForgetToPressSubmitModal*/}
      {/*  isOpen={isDontForgetToSubmitModalOpen && !isAdminFlow}*/}
      {/*  setIsOpen={setIsDontForgetToSubmitModalOpen}*/}
      {/*  isReturnedToRepair={*/}
      {/*    !!Object.keys(fieldsToFix || {}).length ||*/}
      {/*    requestStatus === statusValue.REPAIR_BACK_FOR*/}
      {/*  }*/}
      {/*/>*/}
      <ModalWindow
        forceOpen={isDontForgetToSubmitModalOpen && !isAdminFlow}
        modal_key={
          !!Object.keys(fieldsToFix || {}).length ||
          requestStatus === statusValue.REPAIR_BACK_FOR
            ? 'dontForgetToSubmitReturnedToRepair'
            : 'dontForgetToSubmit'
        }
      />
      <ResultModal />
    </>
  );
};
