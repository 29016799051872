import React from 'react';
import { useTranslation } from 'react-i18next';

import * as CUI from '../../CUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { userModel } from '@entities/user';
import { globalConfig } from '@/globalConfig';
import { selectors } from './model';

/**
 * Check eligibility phone confirmation
 * @returns React.FC
 * */
export const CheckEligibilityFail: React.FC = () => {
  const { t } = useTranslation();
  const isUserConnected = userModel.selectors.useIsLoggedIn();
  const errors = selectors.useServerValidationErrors();
  return (
    <div className="eligibility-fail-container">
      <div className="circle-container check center">
        <FontAwesomeIcon icon={faCircleExclamation} />
      </div>

      <div className="page-title">{t('NOT_ELIGIBLE')}</div>
      <div className="page-subtitle">{t('YOU_CANNOT_GET_SCHOLARSHIP')}</div>
      {!!errors?.length &&
        errors.map((el) => (
          <div className="page-subtitle mob-16">
            {t(`ELIGIBILITY.ERRORS.${el}`)}
          </div>
        ))}
      <Link to={globalConfig.routes.main()}>
        <CUI.Button _btnPrimaryPerach>{t('BACK_TO_HOMEPAGE')}</CUI.Button>
      </Link>
    </div>
  );
};
